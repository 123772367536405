import React from 'react';
import { SectionHeader } from '../shared/section-header/SectionHeader';
import SecretIcon from '../../assets/svg/secret.inline.svg';
import { Project } from './Project';
import * as classes from './Projects.module.scss';

const Projects: React.FC = () => {
    return (
        <section className={classes['projects']}>

            <SectionHeader 
                title="Ongoing Projects"
                description="Projects I'm currently working on."
            />

            <div className={classes['projects__content']}>
                
                <Project 
                    name="Project X"
                    photo={<SecretIcon />}
                    categories={[
                        'Personal',
                        'Development',
                        'Design',
                        'AI'
                    ]}
                />

                <Project 
                    name="RL"
                    photo={<SecretIcon />}
                    categories={[
                        'Personal',
                        'Development',
                        'Design'
                    ]}
                />
            </div>

        </section>
    );
}

export { Projects }