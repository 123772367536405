import React from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';
import * as classes from './FeaturedProject.module.scss';

export interface FeaturedProjectProps {
    name: string;
    photo: React.ReactNode;
    categories: string[];
    path: string;
    featured?: boolean;
}

const FeaturedProject: React.FC<FeaturedProjectProps> = ({
    name,
    photo,
    categories,
    path,
    featured
}) => {
    const containerStyles = classNames({
        [classes['project']]: true,
        [classes['projectFeatured']]: featured
    });

    return (
        <Link to={`/portfolio/${path}`} className={containerStyles}>

            <div className={classes['project__content']}>
                <div className={classes['project__categories']}>
                    {   
                        categories.map((category) => 
                            <div key={category} className={classes['project__category']}>
                                { category }
                            </div>
                        )
                    }
                </div>

                <div className={classes['project__icon']}>
                    { photo }
                </div>

                <div className={classes['project__name']}>
                    { name }
                </div>
            </div>
            
            <div className={classes['project__hover']} />

        </Link>
    );
}

export { FeaturedProject }