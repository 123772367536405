import React from 'react';
import { IProject } from '../../types/Project.interface';
import { projects } from '../../data/projects';
import { FeaturedProject } from './FeaturedProject';
import * as classes from './Featured.module.scss';

const Featured: React.FC = () => {
    return (
        <section className={classes['featured']}>

            {
                projects.map((project: IProject) =>
                    <FeaturedProject 
                        key={project.title}
                        name={project.title}
                        photo={project.icon}
                        categories={[
                            project.type,
                            ...project.categories
                        ]}
                        featured={project.featured}
                        path={project.path}
                    />
                )
            }

        </section>
    );
}

export { Featured }