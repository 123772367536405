import React from 'react';
import * as classes from './Project.module.scss';

export interface ProjectProps {
    name: string;
    photo: React.ReactNode;
    categories: string[];
}

const Project: React.FC<ProjectProps> = ({
    name,
    photo,
    categories
}) => {
    return (
        <article className={classes['project']}>

            <div className={classes['project__categories']}>
                {   
                    categories.map((category) => 
                        <div key={category} className={classes['project__category']}>
                            { category }
                        </div>
                    )
                }
            </div>

            <div className={classes['project__icon']}>
                { photo }
            </div>

            <div className={classes['project__name']}>
                { name }
            </div>
        </article>
    );
}

export { Project }