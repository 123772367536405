import React from 'react';
import { DefaultLayout } from '../../layouts/default';
import { Helmet } from 'react-helmet';
import { Featured, Projects } from '../../components/portfolio';
import * as classes from './index.module.scss';

const Portfolio: React.FC = () => {
    return (
        <DefaultLayout>
            <Helmet>
                <title>Bravecode | Portfolio</title>
                <meta  name="description" content="Portfolio of Krzysztof Szymański known as bravecode. Explore Digital CV and detailed project descriptions. Let's work together!" />
            </Helmet>
            
            <main className={classes['page']}>
                
                <Featured />

                <Projects />

            </main>
        </DefaultLayout>
    )
}

export default Portfolio;